import i18n from "../plugins/i18n";

const unAuthorizedMenuItems = [
  {
    name: i18n.t("calculator"),
    icon: "fa-calculator",
    routeName: "Home",
  },
  {
    name: i18n.t("registration"),
    icon: "fa-user-plus",
    routeName: "Registration",
  },
  {
    name: i18n.t("login_title"),
    icon: "fa-sign-in-alt",
    routeName: "Login",
  },
];

const authorizedMenuItems = [
  {
    name: i18n.t("calculator"),
    icon: "fa-calculator",
    routeName: "Home",
  },
  {
    name: i18n.t("patienst"),
    icon: "fa-notes-medical",
    routeName: "MyPatients",
  },
];

export { authorizedMenuItems, unAuthorizedMenuItems };
