<template>
  <div class="dialog-window">
    <v-dialog v-bind="$attrs" max-width="100%" persistent content-class="ma-0">
      <div class="card">
        <v-alert v-if="validationText" dense type="error">
          {{ validationText }}
        </v-alert>
        <div v-if="title">
          <h2 class="mb-2">{{ title }}</h2>
          <p>{{ text }}</p>
        </div>
        <slot />

        <div
          v-if="!hideBtns"
          class="text-right d-flex flex-column-reverse d-md-block"
        >
          <v-btn
            class="mr-md-2"
            depressed
            text
            @click="$emit('closeDialog', $event)"
            >{{ closeButtonText ? closeButtonText : $t('close') }}</v-btn
          >
          <v-btn
            depressed
            text
            class="primary-btn mb-2 mb-md-0"
            color="primary"
            :loading="dialogLoading"
            @click="$emit('actionButton', $event)"
            >{{ confirmButtonText ? confirmButtonText : $t('confirm') }}</v-btn
          >
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "DialogWindow",
  props: {
    title: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    hideBtns: {
      type: Boolean,
      default: false,
    },
    dialogLoading: {
      type: Boolean,
      default: false,
    },
    closeButtonText: {
      type: String,
      default: "",
    },
    confirmButtonText: {
      type: String,
      default: "",
    },
    validationText: {
      type: String,
      default: ""
    },
    validation: {
      type: Boolean,
      default: false
    }
  },
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
}
</style>
