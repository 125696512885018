<template>
  <div id="registration-form">
    <NotificationAlert :notifications="notifications" class="mb-8" />
    <form @submit.prevent="onSubmit">
      <FormText
        :label="$t('email')"
        v-model="credentials.user.email"
        :validation="$v.credentials.user.email"
      />
      <FormText
        :label="$t('password')"
        type="password"
        class="mt-2"
        v-model="credentials.password"
        :validation="$v.credentials.password"
      />
      <FormText
        :label="$t('password_again')"
        type="password"
        v-model="credentials.passwordAgain"
        :validation="$v.credentials.passwordAgain"
        class="mt-2"
      />
      <v-btn depressed type="submit" color="primary" :loading="loading"
        >{{$t('registrate')}}</v-btn
      >
    </form>
  </div>
</template>

<script>
import useNotification from "@/use/notifications";

import FormText from "@/components/Form/Text.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";
import { credetialsValidation } from "@/use/validations.js";

export default {
  components: { FormText, NotificationAlert },
  validations: {
    ...credetialsValidation,
  },
  data() {
    return {
      credentials: {
        user: {
          email: "",
        },
        password: "",
        passwordAgain: "",
      },
      notifications: [],
      loading: false,
    };
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const paiload = this.credentials;
          await this.$axios.post("doctors", paiload);
          useNotification("Registrace proběhla úspěšně");
          this.notifications = [
            {
              message:
                `Na email ${this.credentials.user.email} jsme Vám zaslali potvzrovací link. Prosím potvrďte registraci před prvním přihlášením.`,
              type: "success",
            },
          ];
        } catch (error) {
          const serverErrors = error.response.data.errors.map((error) => {
            error.type = "error";
            return { ...error };
          });
          this.notifications = [...serverErrors];
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100%;
}
</style>
