<template>
  <v-app>
    <notifications class="notifications" />
    <AppBar @on-mobile-menu-btn="drawer = true" />
    <AppDrawer v-model="drawer" />
    <v-main>
      <router-view />
      <LoginModal />
      <v-footer color="transparent">
        <div class="container text-right grey--text">
          Vlastnikem kalkulátoru PASI skore je TME solutions s. r. o.
        </div>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";
import LoginModal from "./components/Login/LoginModal.vue";
import AppDrawer from "./components/AppDrawer.vue";

export default {
  name: "App",
  components: {
    AppBar,
    LoginModal,
    AppDrawer,
  },
  data() {
    return {
      drawer: false,
    };
  },
};
</script>
