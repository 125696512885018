<template>
  <div id="calculator-result">
    <div class="d-md-flex justify-space-between">
      <h1>{{ $t("result") }}</h1>
      <div>
        <v-btn
          v-if="$store.state.isUserLoggedIn"
          depressed
          color="primary"
          @click="$emit('on-save-patient')"
          class="mb-md-4 base-btn"
        >
          {{ $t(`${AddEvaluation ? "add_evaluation" : "save_patient"}`) }}
        </v-btn>
        <!-- <v-btn depressed color="primary">Vytisknout report</v-btn> -->
      </div>
    </div>
    <v-data-table
      :headers="resultTableHeaders"
      :items="translateBodyParts"
      hide-default-footer
    >
      <template v-slot:body.append="{ headers }">
        <tr class="brown lighten-5">
          <td
            :colspan="headers.length - 1"
            class="text-right font-weight-black text-md-h6 pasi-result-title"
          >
            {{ $t("total_score") }}
          </td>
          <td class="text-h6 text-md-center pasi-result-number">
            {{ form.pasiScore }}
          </td>
        </tr>
      </template>
    </v-data-table>
    <div v-if="form.pasiScore > 10" class="text-right mt-8">
      U pacientů, kteří mají <strong>PASI větší než 10</strong> je možné zvážit
      nasazení
      <a href="/img/AMBIO.pdf" target="_blank" rel="noopener noreferrer"
        >systémové léčby</a
      >
    </div>

    <v-dialog v-model="showRedirectModal" max-width="600">
      <v-card class="pa-8">
        <h1>Zvažte u pacienta biologickou léčbu</h1>
        <p>
          Váš pacient má vzhledem k hodnotě PASI skóre (<strong>{{
            form.pasiScore
          }}</strong
          >) nárok na biologickou léčbu.
        </p>
        <p>
          Registrujte se do aplikace Battalion, která za Vás pohlídá vše
          potřebné od formulářů pro pojišťovnu až po připomínku preskripce.
        </p>
        <div class="text-right">
          <v-btn class="mr-2" depressed @click="closeModal">Zavřít</v-btn>
          <v-btn depressed @click="redirectToBattalion" color="primary"
            >Přejít do Battalion</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import useTrasnlatedBodyParts from "@/use/translateBodyParts.js";

export default {
  name: "CalculatorResult",
  props: {
    form: {
      type: Object,
      required: true,
    },
    AddEvaluation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      resultTableHeaders: [
        {
          text: this.$t("body_region"),
          value: "type",
          sortable: false,
        },
        {
          text: this.$t("erytem"),
          value: "erythema",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("induration"),
          value: "induration",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("scaling"),
          value: "desquamation",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("range"),
          value: "eczema_extent",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("multiplicator"),
          value: "multiplicator",
          align: "center",
          sortable: false,
        },
        {
          text: this.$t("regional_score"),
          value: "regional_score",
          align: "center",
          sortable: false,
        },
      ],
      showRedirectModal: false,
      modalWasClosed: false,
    };
  },
  watch: {
    form: {
      handler() {
        if (this.form.pasiScore > 10 && !this.modalWasClosed) {
          this.showRedirectModal = true;
        }
      },
      deep: true,
    },
  },
  computed: {
    translateBodyParts() {
      return useTrasnlatedBodyParts(this.form.bodyParts);
    },
  },

  methods: {
    closeModal() {
      this.showRedirectModal = false;
      this.modalWasClosed = true;
    },
    redirectToBattalion() {
      window.open("https://battalion.sk/", "_blank");
      this.showRedirectModal = false;
      this.modalWasClosed = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 600px) {
  .pasi-result-title {
    white-space: nowrap;
  }

  .pasi-result-number {
    width: 100%;
    text-align: right;
  }
}
</style>
