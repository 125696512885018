<template>
  <div id="verify-email"></div>
</template>

<script>
export default {
  name: "VerifyEmail",
  async mounted() {
    try {
      const paiload = { email_verification_code: this.$route.query.kod };
      const { data } = await this.$axios.post("doctors/verify-email", paiload);
      this.$router.push({ name: "Home" });
      this.$store.dispatch("setToken", data.auth);
      this.$store.dispatch("setUser", data.user);
    } catch (error) {
      console.log(error);
    }
  },
};
</script>

<style></style>
