<template>
  <v-container>
    <GridPatientPartner v-model="patient" ref="gridPatientPartner" />
    <v-alert v-if="invalidCalculatorForm" type="error" class="mt-4"
      >Je nutné vyplnit údaje v kalkulátoru</v-alert
    >
    <CalculatorCard
      ref="calculatorCard"
      class="my-6"
      v-model="form"
      @on-save-patient="validateForm()"
    />

    <dialog-window
      v-model="showEvaluationDateDialog"
      width="500"
      title="Vložte datum vyhodnocení"
      text="Vepiště prosím datum vyhodnocení PASI score pacienta."
      :dialogLoading="dialogLoading"
      :validationText="showValidationText"
      @actionButton="savePatientDialog"
      @closeDialog="closeEvaluationDateDialog"
    >
      <date-picker label="Datum vyhodnocení" v-model="evaluatedOn" />
    </dialog-window>

    <div class="sources">
      <p><small>Zdroje:</small></p>
      <a
        href="https://www.karger.com/Article/Abstract/250839"
        target="_blank"
        class="mb-2 d-block"
      >
        <small>
          T Fredriksson, U Pettersson. Severe psoriasis--oral therapy with a new
          retinoid. Dermatologica . 1978;157(4):238-44.
        </small>
      </a>

      <a
        href="https://onlinelibrary.wiley.com/doi/10.1111/j.1468-3083.2009.03562.x"
        target="_blank"
        class="mb-2 d-block"
      >
        <small>
          Puzenat E, Bronsard V, Prey S et al. What are the best outcome
          measures for assessing plaque psoriasis severity? A systematic review
          of the literature. J Eur Acad Dermatol Venereol . 2010 Apr;24 Suppl
          2:10-6.
        </small>
      </a>

      <a
        href="https://onlinelibrary.wiley.com/doi/10.1111/jdv.16915"
        target="_blank"
        class="mb-2 d-block"
      >
        <small>
          Nast A, Smith C, Spuls PI et al. EuroGuiDerm Guideline on the systemic
          treatment of Psoriasis vulgaris - Part 1: treatment and monitoring
          recommendations. J Eur Acad Dermatol Venereol. 2020
          Nov;34(11):2461-2498.
        </small>
      </a>
      <small class="mb-2 d-block">
        Cetkovská P., Kojanová M., Arenberger P., Fabianová J., Současný stav
        moderní léčby psoriázy – aktualizovaná doporučení ČDS JEP k cílené léčbě
        závažné chronické psoriázy. Česko-slovenská dermatologie 94, 2019 No.4
        p.135-162. 2
      </small>
      <small
        >Cetkovská P., Kojanová M., Arenberger P. et al. Přehled současných
        doporučených postupů pro systémovou „nebiologickou“ léčbu psoriázy.
        Československá dermatologie 2017; 92 (1): 3–16.
      </small>
    </div>
  </v-container>
</template>

<script>
import GridPatientPartner from "@/components/Grid/PatientPartner.vue";
import CalculatorCard from "@/components/Calculator/Card.vue";
import DialogWindow from "../components/DialogWindow.vue";
import DatePicker from "../components/Form/DatePicker.vue";

export default {
  name: "Home",
  components: {
    GridPatientPartner,
    CalculatorCard,
    DialogWindow,
    DatePicker,
  },

  data() {
    return {
      dialogLoading: false,
      showSavePatientDialog: false,
      showEvaluationDateDialog: false,
      showValidationText: null,
      evaluatedOn: "",
      invalidCalculatorForm: false,
      patient: {
        initials: "",
        birthdate: "",
        gender: "male",
      },
      form: {
        pasiScore: 0,
        bodyParts: [
          {
            type: "head_neck",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.1,
          },
          {
            type: "upper_limbs",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.2,
          },
          {
            type: "torso",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.3,
          },
          {
            type: "lower_limbs",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.4,
          },
        ],
      },
    };
  },

  mounted() {
    this.$store.commit("setAllBodyPartsInActive");
  },

  methods: {
    closeEvaluationDateDialog() {
      this.showValidationText = null;
      this.showEvaluationDateDialog = false;
    },
    async savePatientDialog() {
      if (this.evaluatedOn) {
        try {
          this.showValidationText = null;
          this.dialogLoading = true;
          const patient = { patient: { ...this.patient } };
          const {
            data: {
              patient: { id: patientId },
            },
          } = await this.$axios.post("patients", patient);

          const form = {
            form: {
              body_parts: [...this.form.bodyParts],
              pasi_score: this.form.pasiScore,
              evaluated_on: this.evaluatedOn,
            },
          };

          await this.$axios.post(`/patients/${patientId}/form`, form);

          this.showEvaluationDateDialog = false;

          this.$router.push({ name: "MyPatients" });
        } catch (error) {
          console.log(error);
          this.showValidationText = 'Prosím vyplňte data k pacientovi';
        } finally {
          this.dialogLoading = false;
        }
      } else {
        this.showValidationText = 'Vyplňte prosím datum vyhodnocení';
      }
    },

    validateForm() {
      const gridPatientPartner = this.$refs.gridPatientPartner;
      if (!gridPatientPartner) {
        return false;
      }
      const patientCardInvalid = gridPatientPartner.validateForm();

      if (this.form.pasiScore === 0) {
        this.invalidCalculatorForm = true;
        this.$refs.gridPatientPartner.$el.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }

      if (patientCardInvalid) {
        this.$refs.gridPatientPartner.$el.scrollIntoView({
          behavior: "smooth",
          block: "end",
        });
      }

      if (this.form.pasiScore > 0 && !patientCardInvalid) {
        this.invalidCalculatorForm = false;
        this.showEvaluationDateDialog = true;
      }
    },
  },
};
</script>
