<template>
  <div id="app-bar">
    <v-app-bar app color="primary" elevation="0">
      <div class="d-flex align-center">
        <div class="logo" @click="$router.push({ name: 'Home' })">
          PASI score
        </div>
      </div>

      <v-spacer></v-spacer>

      <div class="menu-wrapper hidden-sm-and-down">
        <ul class="fa-ul">
          <li
            v-for="menuItem in menuItems"
            :key="menuItem.name"
            @click="redirectTorouter(menuItem.routeName)"
            :class="{ active: isActive(menuItem.routeName) }"
          >
            <span class="fa-li"
              ><i :class="`fal ${menuItem.icon} fa-lg`"></i></span
            >{{ menuItem.name }}
          </li>
          <li v-if="this.$store.state.isUserLoggedIn">
            <v-menu offset-y offset-x>
              <template v-slot:activator="{ on }">
                <span v-on="on">
                  <span class="fa-li"> <i class="fal fa-user fa-lg"></i> </span
                  >{{ userEmail }}</span
                >
              </template>
              <v-list dense>
                <v-list-item link @click="singOut">
                  <v-list-item-title
                    ><i class="far fa-sign-out-alt fa-lg mr-2"></i> Odhlásit
                    se</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </li>
        </ul>
      </div>

      <v-btn icon class="hidden-md-and-up" @click="$emit('on-mobile-menu-btn')"
        ><v-icon color="white">mdi-menu</v-icon></v-btn
      >
    </v-app-bar>
  </div>
</template>

<script>
import { authorizedMenuItems, unAuthorizedMenuItems } from "@/use/menuItems";
import useNotification from "@/use/notifications";

export default {
  name: "AppBar",
  computed: {
    menuItems() {
      return this.$store.state.isUserLoggedIn
        ? authorizedMenuItems
        : unAuthorizedMenuItems;
    },
    userEmail() {
      return this.$store.state.user.email;
    },
  },
  methods: {
    isActive(routeName) {
      return routeName === this.$route.name;
    },
    singOut() {
      this.$store.commit("setDefaultState");
      this.$router.push({ name: "Home" });
      useNotification("Došlo k úspěšnmému odhlášení");
    },
    redirectTorouter(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app-bar {
  .logo {
    color: white;
    font-weight: 400;
    font-size: 1.5rem;
    cursor: pointer;
    white-space: nowrap;
  }

  .menu-wrapper {
    li {
      color: white;
      display: inline;
      margin-right: 3rem;
      cursor: pointer;

      &:hover {
        border-bottom: 2px solid white;
      }
    }

    .active {
      border-bottom: 2px solid var(--v-accent-base);
    }
  }
}

.v-menu__content {
  margin-top: 0.5rem;
}
</style>
