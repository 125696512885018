var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.patient)?_c('div',{staticClass:"card",attrs:{"id":"patient-records"}},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('h2',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.$t("patient"))+" "+_vm._s(_vm.patient.initials)+" "+_vm._s(_vm.patient.age)+" ")]),_c('v-menu',{attrs:{"offset-y":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2097855828)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.menuItems),function(menuItem){return _c('v-list-item',{key:menuItem.name,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":menuItem.action}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(menuItem.icon))]),_vm._v(" "+_vm._s(menuItem.name))],1)],1)}),1)],1)],1),_c('v-btn',{staticClass:"my-4 base-btn",attrs:{"depressed":"","color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'Charts', params: { patientId: _vm.patientId } })}}},[_vm._v(_vm._s(_vm.$t("show_charts")))]),_c('div',{staticClass:"d-md-flex patient-recores-table-wrapper"},[_c('div',{attrs:{"id":"patient-records-list"}},[_c('v-btn',{attrs:{"depressed":"","color":"primary"},on:{"click":_vm.redirectToAddEvaluationt}},[_vm._v(_vm._s(_vm.$t("add_evaluation")))]),(_vm.patientForms.length)?_c('v-data-table',{attrs:{"headers":_vm.patientRecordsListHeaders,"items":_vm.patientForms,"footer-props":_vm.footerProps,"item-class":_vm.rowClass},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.evaluated_on",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatedDate(item.evaluated_on))+" ")]}},{key:"item.pasi_score",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.pasi_score)+" "),(item.pasi_score > 10)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"alert-icon"},on),[_vm._v(" mdi-alert-circle-outline ")])]}}],null,true)},[_c('span',[_vm._v(" U pacientů, kteří mají PASI větší než 10 je možné zvážit nasazení systémové léčby")])]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.patientForms.length > 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.$emit('on-delete-patient-form', item)}}},[_vm._v(" mdi-close ")]):_vm._e()]}},{key:"footer.page-text",fn:function(patients){return [_vm._v(" "+_vm._s(patients.pageStop)+" z "+_vm._s(patients.itemsLength)+" ")]}}],null,false,2125200099)}):_vm._e()],1),_c('div',{staticClass:"flex-grow-1",attrs:{"id":"patient-record"}},[_c('h2',{staticClass:"d-md-none",attrs:{"id":"ww"}},[_vm._v("Detail návštěvy")]),(_vm.patientForms.length)?_c('v-data-table',{attrs:{"headers":_vm.patientRecordHeaders,"items":_vm.translateBodyParts,"hide-default-footer":""}}):_vm._e()],1)])],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }