import Vue from "vue";
import VueCompositionAPI from '@vue/composition-api'
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import './styles/index.scss'
import Vuelidate from 'vuelidate'
import axios from "./plugins/axios";
import Notifications from 'vue-notification'
import i18n from './plugins/i18n'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "G-4FGGJV08BL" }
});


Vue.config.productionTip = false;

[VueCompositionAPI, Vuelidate, Notifications, axios].forEach((x) => Vue.use(x));


new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
