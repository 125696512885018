<template>
  <div id="forgotten-password">
    <form v-if="!showSuccesCard" @submit.prevent="onSubmit" class="card">
      <h1>Zapomenuté heslo</h1>
      <NotificationAlert :notifications="errors" class="mb-8" />
      <p>Zadejte prosím svůj email pro obnovení hesla</p>
      <FormText class="mt-8" label="Email" v-model="email" />
      <v-btn type="submit" depressed color="primary" :loading="loading"
        >Odeslat</v-btn
      >
      <div class="d-md-flex justify-space-between mt-8">
        <router-link :to="{ name: 'Login' }"> Přihlásit se</router-link>
        <router-link :to="{ name: 'Registration' }">Registrace</router-link>
      </div>
    </form>

    <div v-else class="card">
      <h1>Obnova hesla</h1>
      Pokud email {{ email }} je v naší databázi, zaslali jsme Vám na tento
      email kód pro obnovu hesla.
      <div class="d-md-flex justify-space-between mt-8">
        <router-link :to="{ name: 'Login' }"> Přihlásit se</router-link>
        <div class="fix-email-link" @click="showSuccesCard = false">
          Zadat jiný email
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/Text.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";

export default {
  components: { FormText, NotificationAlert },
  data() {
    return {
      email: null,
      loading: false,
      errors: [],
      showSuccesCard: false,
    };
  },

  methods: {
    async onSubmit() {
      try {
        this.loading = true;
        const paiload = { email: this.email };
        await this.$axios.post("doctors/forgotten-password", paiload);
        this.showSuccesCard = true;
        this.errors = [];
      } catch (error) {
        const serverErrors = error.response.data.errors.map((error) => {
          error.type = "error";
          return { ...error };
        });
        this.errors = [...serverErrors];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 25rem;
  margin: auto;

  .v-btn {
    width: 100%;
  }

  .fix-email-link {
    color: var(--v-primary-base);
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
