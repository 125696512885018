<script>
import { Bar } from "vue-chartjs";
import ChartDataLabels from 'chartjs-plugin-datalabels';

export default {
  name: "BarChart",
  extends: Bar, ChartDataLabels,
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    options: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
