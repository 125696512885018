<template>
  <v-menu
    v-model="showCalendar"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="computedDateFormatted"
        :label="label"
        outlined
        dense
        readonly
        :append-icon="computedDateFormatted ? 'fal fa-times' : ''"
        v-bind="attrs"
        :error-messages="getErrorMessage"
        v-on="on"
        @click:append="clearDate"
      ></v-text-field>
    </template>
    <v-date-picker
      label="datum narození"
      locale="cs-cz"
      v-model="date"
      :active-picker.sync="activePicker"
      min="1900-01-01"
      :max="maxDate"
      @input="setDate"
    ></v-date-picker>
  </v-menu>
</template>

<script>
import { commonProps, useFields } from "@/use/fields.js";
import formatDate from "@/use/formatDate.js";

export default {
  setup(props, context) {
    const { onBlur } = useFields(props, context);
    return {
      onBlur,
    };
  },
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    showYear: {
      type: Boolean,
      default: false,
    },
    ...commonProps,
  },
  data() {
    return {
      showCalendar: false,
      date: "",
      activePicker: null,
    };
  },
  watch: {
    showCalendar(val) {
      val &&
        setTimeout(() =>
          this.showYear
            ? (this.activePicker = "YEAR")
            : (this.activePicker = null)
        );
    },
  },

  computed: {
    computedDateFormatted() {
      return formatDate(this.date);
    },

    maxDate() {
      const today = new Date();

      const mm = (today.getMonth() + 1 > 9 ? "" : "0") + (today.getMonth() + 1);
      const dd = (today.getDate() > 9 ? "" : "0") + today.getDate();
      const yyyy = today.getFullYear().toString();

      const fullDate = `${yyyy}-${mm}-${dd}`;

      return fullDate;
    },

    getErrorMessage() {
      if (this.validation?.$error) {
        if (this.validation?.required === false) {
          return "Pole je povinné";
        } else if (this.validation?.maxValue === false) {
          return "Hodnota nesmí být vyšší než 100";
        } else if (this.validation?.minValue === false) {
          return "Hodnota musí být vyšší než 0";
        } else if (this.validation?.email === false) {
          return "Email má špatný formát";
        } else if (this.validation?.sameAsPassword === false) {
          return "Hesla se neshodují";
        }
      }
      return "";
    },
  },

  methods: {
    setDate() {
      this.showCalendar = false;
      this.$emit("input", this.date);
    },
    clearDate() {
      this.date = "";
      this.$emit("input", this.date);
    },
  },
};
</script>

<style lang="scss" scoped>
.v-menu__content {
  min-width: auto !important;
}
</style>
