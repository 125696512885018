<template>
  <v-container>
    <div class="card">
      <h2>Pacient: {{ patient.initials }}</h2>
      <v-subheader class="pl-0"
        >Pohlaví: {{ patient.gender }}, narozen:
        {{ patient.birthdate }}</v-subheader
      >
    </div>
    <CalculatorCard
      add-evaluation
      class="my-6"
      v-model="form"
      @on-save-patient="showEvaluationDateDialog = true"
    />
    <dialog-window
      v-model="showSavePatientDialog"
      title="Pacient uložen"
      text="záznam pacienta můžete nalést na stránce"
      @closeDialog="showSavePatientDialog = false"
    />

    <dialog-window
      v-model="showEvaluationDateDialog"
      width="500"
      title="Vložte datum vyhodnocení"
      text="Vepiště prosím datum vyhodnocení PASI score pacienta."
      @actionButton="savePatientDialog"
      @closeDialog="showEvaluationDateDialog = false"
    >
      <date-picker label="Datum vyhodnocení" v-model="evaluatedOn" />
    </dialog-window>
  </v-container>
</template>

<script>
import CalculatorCard from "@/components/Calculator/Card.vue";
import DialogWindow from "../components/DialogWindow.vue";
import DatePicker from "../components/Form/DatePicker.vue";

import useNotification from "@/use/notifications";

export default {
  name: "NewEvaluation",
  components: {
    CalculatorCard,
    DialogWindow,
    DatePicker,
  },

  data() {
    return {
      showSavePatientDialog: false,
      showEvaluationDateDialog: false,
      evaluatedOn: "",
      patient: {
        initials: "",
        birthdate: "",
        gender: "male",
      },
      form: {
        pasiScore: 0,
        bodyParts: [
          {
            type: "head_neck",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.1,
          },
          {
            type: "upper_limbs",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.2,
          },
          {
            type: "torso",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.3,
          },
          {
            type: "lower_limbs",
            erythema: 0,
            induration: 0,
            desquamation: 0,
            eczema_extent: 0,
            eczema_extent_percentage: 0,
            regional_score: 0,
            multiplicator: 0.4,
          },
        ],
      },
    };
  },

  computed: {
    patientId() {
      return this.$route.params.patientId;
    },
  },

  async mounted() {
    try {
      ({
        data: { patient: this.patient },
      } = await this.$axios.get(`/patients/${this.patientId}`));
    } catch (error) {
      console.log(error);
    }

    this.$store.commit("setAllBodyPartsInActive");
  },

  methods: {
    async savePatientDialog() {
      try {
        const form = {
          form: {
            body_parts: [...this.form.bodyParts],
            pasi_score: this.form.pasiScore,
            evaluated_on: this.evaluatedOn,
          },
        };

        await this.$axios.post(`/patients/${this.patientId}/form`, form);

        this.showEvaluationDateDialog = false;
        this.$router.push({ name: "MyPatients" });
        useNotification(
          `Vyhodnocení pacienta ${this.patient.initials} uloženo`
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
