<template>
  <div id="registration-card" class="card">
    <h1>Registrace</h1>
    <RegistrationForm />
    <div class="text-center mt-8">
      {{$t('account_sing_up')}}
      <router-link :to="{ name: 'Login' }">{{$t('sign_up_here')}}</router-link>
    </div>
  </div>
</template>

<script>
import RegistrationForm from "@/components/Form/Registration/RegistrationForm.vue";
export default {
  components: {
    RegistrationForm,
  },
};
</script>

<style lang="scss" scoped>
#registration-card {
  &.card {
    max-width: 25rem;
    margin: auto;
  }
}
</style>
