<template>
  <LoginCard />
</template>

<script>
import LoginCard from "@/components/Login/LoginCard";
export default {
  name: "Login",
  components: {
    LoginCard,
  },
};
</script>

<style></style>
