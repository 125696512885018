<template>
  <div class="card">
    <h1>{{$t('app_partner')}}</h1>
    <v-carousel height="300" hide-delimiters :show-arrows="false" cycle>
      <v-carousel-item>
        <a href="https://cz.dermaworld.eu/" target="_blank"
          ><v-img
            :src="'./img/LEO_banner.jpg'"
            width="600"
            contain
            class="mx-auto"
        /></a>
      </v-carousel-item>
      <v-carousel-item>
        <a href="https://cz.dermaworld.eu/" target="_blank">
          <v-img
            :src="'./img/LEO_logo.png'"
            width="400"
            contain
            class="mx-auto"
          />
        </a>
      </v-carousel-item>
      <v-carousel-item>
        <a href="https://www.tmesolutions.cz//" target="_blank">
          <v-img
            :src="'./img/TME_solutions_logo.png'"
            width="500"
            contain
            class="mx-auto"
          />
        </a>
      </v-carousel-item>
      <v-carousel-item>
        <a href="https://www.tmesolutions.cz//" target="_blank">
          <v-img
            :src="'./img/triamed_logo.png'"
            width="500"
            contain
            class="mx-auto"
          />
        </a>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  data() {
    return {
      partners: [
        {
          img: "@/styles/img/LEO_banner.jpg",
          link: "https://cz.dermaworld.eu/",
        },
        {
          img: "@/styles/img/LEO_logo.png",
          link: "http://www.leopharma.cz/",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
  width: 100%;
}
</style>
