<template>
  <v-select
    v-bind="$props"
    :value="value"
    @input="onInput"
    :menu-props="{ offsetY: true }"
  />
</template>

<script>
import { commonProps, useFields } from "@/use/fields.js";

export default {
  name: "SelectBox",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    items: {
      type: Array,
      required: true,
    },
    ...commonProps,
  },
  setup(props, context) {
    const { onInput } = useFields(props, context);
    return {
      onInput,
    };
  },
};
</script>