import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { customProperties: true },
    themes: {
      light: {
        primary: "#204131",
        secondary: "#efebe9",
        accent: "#db6327",
        error: "#b71c1c",
        success: "#1fa51f"
      },
    },
  },
});
