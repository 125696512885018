<template>
  <div id="reset-password">
    <div class="card">
      <NotificationAlert
        v-if="notifications.length"
        :notifications="notifications"
        class="mb-8"
      />
      <form @submit.prevent="onSubmit">
        <h1>Obnova hesla</h1>
        <FormText
          label="Heslo"
          type="password"
          class="mt-2"
          v-model="password"
          :validation="$v.password"
        />
        <FormText
          label="Heslo znovu"
          type="password"
          v-model="passwordAgain"
          :validation="$v.passwordAgain"
          class="mt-2"
        />
        <v-btn depressed type="submit" color="primary" :loading="loading"
          >Resetovat heslo</v-btn
        >
      </form>
    </div>
  </div>
</template>

<script>
import FormText from "@/components/Form/Text.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";
import useNotification from "@/use/notifications";

import { ResetPasswordValidation } from "@/use/validations.js";

export default {
  name: "ResetPassword",
  components: { FormText, NotificationAlert },
  validations: {
    ...ResetPasswordValidation,
  },
  data() {
    return {
      password: "",
      passwordAgain: "",
      loading: false,
      notifications: [],
    };
  },

  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          const paiload = {
            new_password: this.password,
            recovery_code: this.$route.query.kod,
          };
          const { data } = await this.$axios.post(
            "doctors/password-reset",
            paiload
          );
          this.$store.dispatch("setToken", data.auth);
          this.$store.dispatch("setUser", data.user);
          const token = data.auth.access_token;
          this.$axios.defaults.headers.common[
            "Authorization"
          ] = `BEARER ${token}`;
          useNotification("Změna hesla proběhla úspěšně");
          this.$router.replace({ name: "Home" });
        } catch (error) {
          const serverErrors = error.response.data.errors.map((error) => {
            error.type = "error";
            return { ...error };
          });
          this.notifications = [...serverErrors];
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#reset-password {
  .card {
    max-width: 25rem;
    margin: auto;

    .v-btn {
      width: 100%;
    }
  }
}
</style>
