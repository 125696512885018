<template>
  <div class="notification-alert">
    <v-alert
      v-for="(notification, index) in notifications"
      :key="index"
      dense
      text
      :type="notification.type"
    >
      {{ notification.message }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: "NotificationAlert",
  props: {
    notifications: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style>
</style>