import { required, email, sameAs } from "vuelidate/lib/validators";

export const credetialsValidation = {
  credentials: {
    user: {
      email: {
        required,
        email,
      },
    },
    password: {
      required,
    },
    passwordAgain: {
      sameAsPassword: sameAs("password"),
    },
  },
};

export const ResetPasswordValidation = {
  password: {
    required,
  },
  passwordAgain: {
    sameAsPassword: sameAs("password"),
  },
};

export const logInValidation = {
  credentials: {
    email: {
      required,
      email,
    },
    password: {
      required,
    },
  },
};

export const patientInfoValidation = {
  patient: {
    initials: { required },
    birthdate: { required },
  },
};
