import axios from "axios";
import checkTokenExpiration from "../use/checkTokenExpiration";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const countryCode = location.host.replace(/^.*\./, "");

axios.defaults.headers["Accept-Language"] = countryCode === "sk" ? "sk-SK" : "cs-CZ";

axios.interceptors.request.use(async (config) => {
  const localStorageItem = JSON.parse(localStorage.getItem("vuex"));
  const token = localStorageItem?.token?.access_token;

  if (token) {
    config.headers["Authorization"] = `BEARER ${token}`;
  }

  if (!config.url.match("doctors/token")) {
    const updatedToken = await checkTokenExpiration();
    if (updatedToken) {
      config.headers["Authorization"] = `BEARER ${updatedToken}`;
    }
  }
  return config;
});

export default {
  install(Vue) {
    Vue.prototype.$axios = axios;
  },
};
