<template>
  <v-text-field
    v-bind="$props"
    :type="type"
    :value="value"
    :error-messages="getErrorMessage"
    :min="min"
    :max="max"
    @input="onInput"
    @blur="onBlur"
  />
</template>

<script>
import { commonProps, useFields } from "@/use/fields.js";

export default {
  name: "TextField",
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
    ...commonProps,
  },
  setup(props, context) {
    const { onInput, onBlur } = useFields(props, context);
    return {
      onInput,
      onBlur
    };
  },
  computed: {
    getErrorMessage() {
      if (this.validation?.$error) {
        if (this.validation?.required === false) {
          return "Pole je povinné";
        } else if (this.validation?.maxValue === false) {
          return "Hodnota nesmí být vyšší než 100";
        } else if (this.validation?.minValue === false) {
          return "Hodnota musí být vyšší než 0";
        } else if (this.validation?.email === false) {
          return "Email má špatný formát";
        } else if (this.validation?.sameAsPassword === false) {
          return "Hesla se neshodují";
        }
      }
      return "";
    },
  },
};
</script>

<style>
</style>