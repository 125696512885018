<template>
  <div id="patient-records-grid">
    <div class="d-md-flex">
      <PatientsList
        :patients="patients"
        class="mb-4 mr-md-4 mb-md-0"
        @click-row="clickOnRow"
      />
      <PatientRecords
        class="flex-grow-1 hidden-sm-and-down"
        :patient="patient"
        :patient-forms="patientForms"
        @on-edit-patient="$emit('on-edit-patient')"
        @on-delete-patient="$emit('on-delete-patient')"
        @on-delete-patient-form="$emit('on-delete-patient-form', $event)"
        @on-send-report="$emit('on-send-report', $event)"
      />

      <DialogWindow v-model="showPatientRecord" hideBtns>
        <v-btn icon class="close-btn" @click="showPatientRecord = false">
          <v-icon> mdi-close</v-icon></v-btn
        >
        <PatientRecords
          class="flex-grow-1 d-md-none patient-record-mobile"
          :patient="patient"
          :patient-forms="patientForms"
          @on-edit-patient="$emit('on-edit-patient')"
          @on-delete-patient="$emit('on-delete-patient')"
          @on-delete-patient-form="$emit('on-delete-patient-form', $event)"
          @on-send-report="$emit('on-send-report', $event)"
        />
      </DialogWindow>
    </div>
  </div>
</template>

<script>
import PatientsList from "../Patient/PatientsList.vue";
import PatientRecords from "../Patient/PatientRecords.vue";
import DialogWindow from "../DialogWindow.vue";

export default {
  components: { PatientsList, PatientRecords, DialogWindow },
  props: {
    patients: {
      type: Array,
      required: true,
    },
    patientForms: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showPatientRecord: false,
    };
  },

  computed: {
    patient() {
      return this.patients.find(
        ({ id }) => id === parseInt(this.$route.params.patientId)
      );
    },
  },

  methods: {
    clickOnRow(value) {
      if (this.$vuetify.breakpoint.smAndDown) {
        this.showPatientRecord = true;
        this.$emit("click-row", value);
      } else {
        this.$emit("click-row", value);
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.close-btn {
  position: absolute;
  right: 1rem;
  top: 1rem
}

.patient-record-mobile {
  padding-top: 4rem;
}
</style>
