import Vue from 'vue';

function useNotifications(title, duration, type) {
  Vue.notify({
    title: title,
    type: type ? type : 'success',
    duration: duration ? duration : 4000,
  });
}

export default useNotifications;
