<template>
  <div id="grid-user-partner">
    <v-row>
      <v-col md="6">
        <patient-card :patient="patient" ref="patientCard" @patient="$emit('input', $event)" />
      </v-col>
      <v-col md="6">
        <partner-card />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import PatientCard from "@/components/Patient/Card";
import PartnerCard from "@/components/Partner/Card";

export default {
  model: {
    prop: "patient",
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  name: "GridUserParnter",
  components: {
    PatientCard,
    PartnerCard,
  },
  methods: {
    validateForm() {
      const patientCard = this.$refs.patientCard;
      if (!patientCard) {
        return false;
      }

      return patientCard.validateForm();
    },
  },
};
</script>
