import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import axios from 'axios';

Vue.use(Vuex);

const getDefaultState = {
  user: null,
  token: null,
  isUserLoggedIn: false,
  headNeckHovered: false,
  torsoHovered: false,
  upperLimbsHovered: false,
  showLoginModal: false,
  lowerLimbHovered: false,
  bodyPartsActive: {
    headNeckActive: false,
    torsoActive: false,
    upperLimbsActive: false,
    lowerLimbActive: false,
  },
};

const getDefaultBodyPartsActive = {
  headNeckActive: false,
  torsoActive: false,
  upperLimbsActive: false,
  lowerLimbActive: false,
};

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    isUserLoggedIn: false,
    showLoginModal: false,
    headNeckHovered: false,
    torsoHovered: false,
    upperLimbsHovered: false,
    lowerLimbHovered: false,
    bodyPartsActive: {
      headNeckActive: false,
      torsoActive: false,
      upperLimbsActive: false,
      lowerLimbActive: false,
    },
  },
  mutations: {
    setDefaultState(state) {
      Object.assign(state, getDefaultState);
    },
    setUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
      if (token) {
        state.isUserLoggedIn = true;
      }
    },
    setLoginModal(state, value) {
      state.showLoginModal = value;
    },
    setHeadNeckHovered(state, value) {
      state.headNeckHovered = value;
    },
    setAllBodyPartsInActive(state) {
      Object.assign(state.bodyPartsActive, getDefaultBodyPartsActive);
    },
    setBodyPartActive(state, key) {
      Vue.set(state.bodyPartsActive, key, true);
    },
    setTorsoHovered(state, value) {
      state.torsoHovered = value;
    },
    setUpperLimbsHovered(state, value) {
      state.upperLimbsHovered = value;
    },
    setLowerLimbHovered(state, value) {
      state.lowerLimbHovered = value;
    },
    setHeadNeckActive(state, value) {
      state.headNeckActive = value;
    },
    setTorsoActive(state, value) {
      state.torsoActive = value;
    },
    setUpperLimbsActive(state, value) {
      state.upperLimbsActive = value;
    },
    setLowerLimbActive(state, value) {
      state.lowerLimbActive = value;
    },
  },
  actions: {
    setUser({ commit }, user) {
      commit('setUser', user);
    },
    setToken({ commit }, token) {
      commit('setToken', token);
    },

    async fetchFreshToken({ commit }, refreshToken) {
      try {
        const paiload = { refresh_token: refreshToken };
        const {
          data: { auth: token },
        } = await axios.post('doctors/token', paiload);
        commit('setToken', token);
      } catch (error) {
        console.log(error);
        commit('setLoginModal', true)
      }
    },
  },
  modules: {},
  plugins: [new VuexPersistence().plugin],
});
