<template>
  <v-navigation-drawer :value="drawer" fixed temporary right @input="setDrawer">
    <div v-if="$store.state.isUserLoggedIn">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ $store.state.user.email }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>
    </div>
    <v-list dense>
      <v-list-item
        v-for="menuItem in menuItems"
        :key="menuItem.name"
        link
        :class="{ active: isActive(menuItem.routeName) }"
        @click="redirectTorouter(menuItem.routeName)"
      >
        <v-list-item-icon>
          <v-icon>{{ menuItem.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ menuItem.name }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-if="$store.state.isUserLoggedIn" v-slot:append>
      <div class="pa-4">
        <v-btn depressed block @click="singOut"> Odhlásit se</v-btn>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { authorizedMenuItems, unAuthorizedMenuItems } from "@/use/menuItems";
import useNotification from "@/use/notifications";

export default {
  name: "AppDrawer",
  model: {
    prop: "drawer",
  },
  props: {
    drawer: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    setDrawer(value) {
      this.$emit("input", value);
    },
    isActive(routeName) {
      return routeName === this.$route.name;
    },
    redirectTorouter(routeName) {
      if (this.$route.name !== routeName) {
        this.$router.push({ name: routeName });
      } else {
        this.$emit("input", false);
      }
    },
    singOut() {
      this.$store.commit("setDefaultState");
      this.$router.push({ name: "Home" });
      useNotification("Došlo k úspěšnmému odhlášení");
    },
  },
  computed: {
    menuItems() {
      return this.$store.state.isUserLoggedIn
        ? authorizedMenuItems
        : unAuthorizedMenuItems;
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background-color: gainsboro;
}
</style>
