<template>
  <div id="calculator-card">
    <div class="card mb-6">
      <h1>{{ $t("calculation") }}</h1>
      <div class="d-md-flex">
        <div class="d-flex flex-grow-1">
          <HumanSilhouetteFront class="hidden-sm-and-down" />
          <HumanSilhouetteBack class="hidden-sm-and-down" />
        </div>
        <div>
          <FormCalculator :bodyParts="bodyParts" @input="getForm" />
          <v-btn
            id="show-result-btn"
            depressed
            color="primary"
            @click="$vuetify.goTo('#calculator-result')"
            >{{ $t("show_result") }}</v-btn
          >
        </div>
      </div>
    </div>
    <div class="card">
      <CalculatorResult
        :add-evaluation="addEvaluation"
        :form="form"
        @on-save-patient="$emit('on-save-patient')"
      />
    </div>
  </div>
</template>

<script>
import FormCalculator from "@/components/Form/Calculator/FormCaluclator.vue";
import CalculatorResult from "@/components/Calculator/Result.vue";
import HumanSilhouetteBack from "@/components/HumanSilhouette/HumanSilhouetteBack.vue";
import HumanSilhouetteFront from "@/components/HumanSilhouette/HumanSilhouetteFront.vue";

export default {
  name: "CalculatorCard",
  model: {
    prop: "form",
  },
  props: {
    form: {
      type: Object,
      required: true,
    },
    addEvaluation: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FormCalculator,
    CalculatorResult,
    HumanSilhouetteBack,
    HumanSilhouetteFront,
  },
  computed: {
    bodyParts() {
      return this.form.bodyParts;
    },
  },
  methods: {
    getForm(value) {
      const totalPasiScore = () =>
        parseFloat(
          value
            .reduce((prev, cur) => {
              const parsedScore = parseFloat(cur.regional_score) || 0;
              return prev + parsedScore;
            }, 0)
            .toFixed(2)
        );

      const updatedForm = {
        pasiScore: totalPasiScore(),
        bodyParts: [...value],
      };

      this.$emit("input", updatedForm);
    },
  },
};
</script>

<style lang="scss" scoped>
#show-result-btn {
  width: 100%;
}
</style>
