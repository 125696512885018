import Vue from "vue";
import VueI18n from "vue-i18n";
import cs from "../locales/cs.json";
import sk from "../locales/sk.json";

Vue.use(VueI18n);

const countryCode = location.host.replace(/^.*\./, "");

let language = countryCode === "sk" ? "sk" : "cs";

if (process.env.BASE_URL === '') {
  const deviceHasSkLanguage = navigator.language === 'sk-sk' || navigator.language === 'sk-SK'
  language = deviceHasSkLanguage ? 'sk' : 'cs'
}

export default new VueI18n({
  locale: language,
  fallbackLocale: "cs",
  silentFallbackWarn: true,
  messages: {
    cs: cs,
    sk: sk,
  },
});
