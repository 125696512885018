import i18n from '../plugins/i18n';

export default function translateBodyPart(bodyParts) {
  return Array.isArray(bodyParts)
    ? bodyParts.map((bodyPart) => {
        const bodyPartTranslated = { ...bodyPart };
        switch (bodyPart.type) {
          case 'head_neck':
            bodyPartTranslated.type = i18n.t('head_Neck');
            break;
          case 'upper_limbs':
            bodyPartTranslated.type = i18n.t('upper_limbs');
            break;
          case 'torso':
            bodyPartTranslated.type = i18n.t('trunk_tab');
            break;
          case 'lower_limbs':
            bodyPartTranslated.type = i18n.t('lower_limbs');
            break;
        }
        return bodyPartTranslated;
      })
    : [];
}
