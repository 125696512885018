<template>
  <div>
    <div class="card">
      <v-tabs v-model="bodyPartTab" show-arrows centered>
        <v-tab
          v-for="bodyPart in translateBodyParts"
          :key="bodyPart.type"
          :class="bodyPart.type"
          @click="makeBodyPartActive(bodyPart.type)"
        >
          {{ bodyPart.type }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="bodyPartTab">
        <v-tab-item v-for="(bodyPart, index) in bodyParts" :key="bodyPart.type">
          <FormCalculatorBodyPart
            @item="onInput($event, index)"
            :item="bodyPart"
          />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import FormCalculatorBodyPart from "@/components/Form/Calculator/FormCalculatorBodyPart";
import useTrasnlatedBodyParts from "@/use/translateBodyParts.js";

export default {
  model: {
    prop: "bodyParts",
  },
  components: {
    FormCalculatorBodyPart,
  },
  props: {
    bodyParts: {
      type: Array,
      required: true,
    },
  },
  computed: {
    translateBodyParts() {
      return useTrasnlatedBodyParts(this.bodyParts);
    },
  },
  data() {
    return {
      bodyPartTab: null,
    };
  },
  methods: {
    onInput(event, index) {
      const bodyPartsUpdated = [...this.bodyParts];
      bodyPartsUpdated[index] = event;
      this.$emit("input", bodyPartsUpdated);
    },

    makeBodyPartActive(bodyPart) {
      switch (bodyPart) {
        case this.$t('head_Neck'):
          this.$store.commit("setAllBodyPartsInActive");
          this.$store.commit("setBodyPartActive", "headNeckActive");
          break;
        case this.$t('upper_limbs'):
          this.$store.commit("setAllBodyPartsInActive");
          this.$store.commit("setBodyPartActive", "upperLimbsActive");
          break;
        case this.$t('trunk_tab'):
          this.$store.commit("setAllBodyPartsInActive");
          this.$store.commit("setBodyPartActive", "torsoActive");
          break;
        case this.$t('lower_limbs'):
          this.$store.commit("setAllBodyPartsInActive");
          this.$store.commit("setBodyPartActive", "lowerLimbActive");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  padding: 0;
  box-shadow: none;
  border: 1px solid gainsboro;
  border-radius: 0;
  .v-tabs {
    ::v-deep .v-tabs-bar {
      background-color: var(--v-secondary-base);
    }
  }
}

@media screen and (max-width: 960px) {
  .card {
    border: none;
  }

  .v-tabs {
    margin: 0 -2rem;
    width: auto;
  }
}
</style>
