import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import MyPatients from "../views/MyPatients.vue";
import Login from "../views/Login.vue";
import Registration from "../views/Registration.vue";
import NewEvaluation from "../views/NewEvaluation";
import ForgottenPassword from "../views/ForgottenPassword";
import VerifyEmail from "../views/VerifyEmail";
import Charts from "../views/Charts";
import ResetPassword from "../views/ResetPassword";
import store from "../store/index.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/moji-pacienti/:patientId?/:formId?",
    name: "MyPatients",
    component: MyPatients,
    meta: { authorized: true },
  },
  {
    path: "/prihlaseni",
    name: "Login",
    component: Login,
    meta: { authorized: false },
  },
  {
    path: "/registrace",
    name: "Registration",
    component: Registration,
    meta: { authorized: false },
  },
  {
    path: "/nove-vyhodnoceni/:patientId?",
    name: "NewEvaluation",
    component: NewEvaluation,
    meta: { authorized: true },
  },
  {
    path: "/zapomenute-heslo",
    name: "ForgottenPassword",
    component: ForgottenPassword,
    meta: { authorized: false },
  },
  {
    path: "/overeni-emailu",
    name: "VerifyEmail",
    component: VerifyEmail,
    meta: { authorized: false },
  },
  {
    path: "/grafy/:patientId?",
    name: "Charts",
    component: Charts,
    meta: { authorized: true },
  },
  {
    path: "/obnova-hesla/",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { authorized: false },
  },
  {
    path: "/android",
    name: "Android"
  },
  {
    path: "/ios",
    name: "Ios"
  },
];

const router = new VueRouter({
  mode: process.env.BASE_URL === "" ? "hash" : "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  const isAuthorized = store.state.isUserLoggedIn;

  const refresTokenExpirationDate = new Date(
    store.state?.token?.refresh_token_expires_at
  );

  function checkRefreshTokenExpiration() {
    if (new Date() > refresTokenExpirationDate) {
      store.commit("setLoginModal", true);
    } else {
      next();
    }
  }

  function authorizedRoutes() {
    isAuthorized ? checkRefreshTokenExpiration() : next({ name: "Login" });
  }

  function unAuthorizedRoutes() {
    isAuthorized ? next({ name: "Home" }) : next();
  }

  if (to.name === 'Ios') {
    window.location.replace("https://apps.apple.com/us/app/pasi-score/id1586998042");
  }

  if (to.name === 'Android') {
    window.location.replace("https://play.google.com/store/apps/details?id=cz.tmesolutions.pasi");
  }


  if (to.meta.authorized !== undefined) {
    to.meta.authorized ? authorizedRoutes() : unAuthorizedRoutes();
  } else {
    next();
  }
});

export default router;
