<template>
  <v-container>
    <div v-if="loading">
      <v-overlay>
        <v-progress-circular
          :size="70"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-overlay>
    </div>
    <div v-else id="my-patients">
      <h1>{{ $t("patienst") }}</h1>

      <PatientsRecordsGrid
        :patients="patients"
        :patient-forms="patientForms"
        @on-delete-patient="showDeletePatient = true"
        @click-row="onRowClick"
        @on-delete-patient-form="setShoDeletePatientForm"
        @on-send-report="showSendReport = true"
      />

      <div v-if="!patients.length" class="card text-center">
        <h2 class="mb-12">Zatím nemáte uložné žádné data</h2>
        <v-img
          src="../assets/no_data.svg"
          max-width="350"
          class="mx-auto mb-12"
        ></v-img>
        <v-btn color="primary" depressed @click="$router.push({ name: 'Home' })"
          >Zpět na kalkulátor</v-btn
        >
      </div>
    </div>

    <DialogWindow
      v-if="patient"
      title="Opravdu chcete smazat formulář?"
      v-model="showDeletePatientForm"
      width="500"
      @actionButton="deletePatientForm"
      @closeDialog="showDeletePatientForm = false"
    >
      <p class="mb-8">
        Prosím potvrďte jestli chcete smazat formulář u pacienta
        <strong>{{ patient.initials }}</strong>
      </p>
    </DialogWindow>

    <DialogWindow
      v-if="patient"
      :title="$t('remove_confirm_message')"
      v-model="showDeletePatient"
      width="500"
      @actionButton="deletePatient"
      @closeDialog="showDeletePatient = false"
    >
      <p class="mb-8">
        Prosím potvrďte jestli chcete smazat pacienta
        <strong>{{ patient.initials }}</strong>
      </p>
    </DialogWindow>

    <DialogWindow
      :title="$t('send_report')"
      :text="$t('send_report_to_email_text')"
      v-model="showSendReport"
      width="500"
      :dialogLoading="sendReportLoading"
      @actionButton="sendReport"
      @closeDialog="showSendReport = false"
    >
      <FormText label="Email" v-model="email" />
    </DialogWindow>
  </v-container>
</template>

<script>
import PatientsRecordsGrid from "@/components/Grid/PatientsRecordsGrid";
import DialogWindow from "../components/DialogWindow.vue";
import FormText from "../components/Form/Text.vue";

import useNotification from "@/use/notifications";

export default {
  name: "MyPatients",
  components: {
    PatientsRecordsGrid,
    DialogWindow,
    FormText,
  },

  data() {
    return {
      patients: [],
      patientForms: [],
      loading: false,
      showSendReport: false,
      showDeletePatient: false,
      email: "",
      showDeletePatientForm: false,
      sendReportLoading: false,
      deleteFrom: null,
    };
  },

  computed: {
    patientId() {
      return this.$route.params.patientId;
    },

    formId() {
      return this.$route.params.formId;
    },

    firstPatientForm() {
      return this.patientForms.length ? this.patientForms[0].id : null;
    },

    firstPatientId() {
      return this.patients.length ? this.patients[0].id : null;
    },

    patient() {
      return this.patients.find(
        (patient) => patient.id === parseInt(this.patientId)
      );
    },
  },

  async mounted() {
    await this.fetchPatients();

    //Fetch first form
    if (!this.$route.params.patientId) {
      this.fetchFirstPatientForm();
    } else {
      await this.fetchPatientForm(this.patientId);
    }
  },

  methods: {
    async fetchPatients() {
      try {
        this.loading = true;
        const {
          data: { patients: patients },
        } = await this.$axios.get("patients");

        //Sort by newest patient
        this.patients = patients.slice().reverse();

        //Caluculate patient age
        this.getAge(this.patients);
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async fetchPatientForm(patientId) {
      try {
        const {
          data: { forms: patientForms },
        } = await this.$axios.get(`patients/${patientId}`);
        this.patientForms = patientForms;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchFirstPatientForm() {
      await this.fetchPatientForm(this.firstPatientId);
      this.$router.replace({
        params: {
          patientId: this.firstPatientId,
          formId: this.firstPatientForm,
        },
      });
    },

    async deletePatient() {
      try {
        await this.$axios.delete(`patients/${this.patientId}`);
        await this.fetchPatients();
        await this.fetchFirstPatientForm();
        this.showDeletePatient = false;
      } catch (error) {
        console.log(error);
      }
    },

    async deletePatientForm() {
      try {
        await this.$axios.delete(
          `patients/${this.patientId}/form/${this.deleteFrom.id}`
        );
        await this.fetchPatientForm(this.patientId);
        this.$router.replace({
          params: {
            formId: this.patientForms[this.patientForms.length - 1].id,
          },
        });
        this.showDeletePatientForm = false;
        useNotification(this.$t('form_deleted'));
      } catch (error) {
        console.log(error);
      }
    },

    async sendReport() {
      try {
        this.sendReportLoading = true;
        const forms = [];

        this.patientForms.map((patientForm) => {
          const form = {
            id: patientForm.id,
            visit: patientForm.evaluated_on,
            pasi_score: patientForm.pasi_score,
            severity: "test",
          };

          forms.push(form);
        });

        const paiload = {
          recipient_email: this.email,
          forms: [...forms],
        };

        await this.$axios.post(
          `patients/${this.patientId}/forms/send-score`,
          paiload
        );

        useNotification(this.$t('report_sended'));

        this.showSendReport = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.sendReportLoading = false;
      }
    },

    async onRowClick(patient) {
      if (parseInt(this.$route.params.patientId) !== patient.id) {
        this.$router.replace({ params: { patientId: patient.id } });
        await this.fetchPatientForm(this.patientId);

        this.$router.replace({ params: { formId: this.firstPatientForm } });
      }
    },

    setShoDeletePatientForm(value) {
      this.showDeletePatientForm = true;
      this.deleteFrom = value;
    },

    getAge(patients) {
      patients.forEach((patient) => {
        const birthdate = new Date(patient.birthdate);
        const difference = Date.now() - birthdate.getTime();
        const ageDate = new Date(difference);
        const age = Math.abs(ageDate.getUTCFullYear() - 1970);

        patient.age = age;

        if (patient.gender === "male") {
          patient.gender = "Muž";
        } else {
          patient.gender = "Žena";
        }
      });
    },
  },
};
</script>

<style></style>
