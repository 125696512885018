const commonProps = {
  outlined: {
    type: Boolean,
    default: true,
  },
  dense: {
    type: Boolean,
    default: true,
  },
  label: {
    type: String,
    default: '',
  },
  hideDetails: {
    type: Boolean,
    default: false,
  },
  clearable: {
    type: Boolean,
    default: false,
  },
  type: {
    type: String,
    default: 'text',
  },
  min: {
    type: Number,
    default: null,
  },
  max: {
    type: Number,
    default: null,
  },
  validation: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: false
  },
  validateOff: {
    type: Boolean,
    default: false
  }
};

function useFields(props, context) {
  function onInput(event) {
    context.emit('input', event);
  }
  function onBlur(event) {
    if (props.validation && !props.validateOff) {
      props.validation.$touch();
    }
    context.emit('blur', event);
  }
  return {
    onInput,
    onBlur,
  };
}

export { commonProps, useFields };
export default useFields;
