<template>
  <div class="py-8 pa-md-8">
    <h3 class="mb-4">{{ $t("assessment_eczema_region") }}</h3>
    <div class="d-md-flex">
      <form-text
        id="percentage-input"
        :value="item.eczema_extent_percentage"
        :validation="$v.item.eczema_extent_percentage"
        :label="$t('rating_range')"
        type="number"
        :min="0"
        :max="100"
        class="mb-4 mb-md-0 mr-md-4"
        @input="onInput($event, 'eczema_extent_percentage', item)"
      />
      <form-text
        :value="areaScore"
        label="Skóre oblasti"
        :outlined="false"
        id="area-score"
      />
    </div>
    <h3 class="mt-4">{{ $t("assessment_severity") }}</h3>
    <v-subheader class="pl-0">{{ $t("degree_severity_erytem") }}</v-subheader>
    <v-row>
      <v-col md="6" cols="12">
        <div class="d-md-flex">
          <v-slider
            max="4"
            :value="item.erythema"
            @input="onInput($event, 'erythema', item)"
            class="mr-2 hidden-sm-and-down"
          ></v-slider>
          <form-text
            type="number"
            :value="item.erythema"
            @input="onInput($event, 'erythema', item)"
            class="small-input mr-md-4"
          />
        </div>
      </v-col>
      <v-col>
        <form-select
          :value="item.erythema"
          :label="$t('erytem_severity')"
          :items="gravityOptions"
          :outlined="false"
          @input="onInput($event, 'erythema', item)"
          clearable
        />
      </v-col>
    </v-row>
    <v-subheader class="pl-0">{{
      $t("degree_severity_induration")
    }}</v-subheader>
    <v-row>
      <v-col md="6" cols="12">
        <div class="d-flex">
          <v-slider
            max="4"
            :value="item.induration"
            @input="onInput($event, 'induration', item)"
            class="mr-2 hidden-sm-and-down"
          ></v-slider>
          <form-text
            type="number"
            :value="item.induration"
            @input="onInput($event, 'induration', item)"
            class="small-input mr-md-4"
          />
        </div>
      </v-col>
      <v-col>
        <form-select
          type="number"
          :value="item.induration"
          :label="$t('induration_severity')"
          :items="gravityOptions"
          :outlined="false"
          @input="onInput($event, 'induration', item)"
          clearable
        />
      </v-col>
    </v-row>
    <v-subheader class="pl-0">{{ $t("degree_severity_scaling") }}</v-subheader>
    <v-row>
      <v-col md="6" cols="12">
        <div class="d-flex">
          <v-slider
            max="4"
            :value="item.desquamation"
            @input="onInput($event, 'desquamation', item)"
            class="mr-2 hidden-sm-and-down"
          ></v-slider>
          <form-text
            type="number"
            :value="item.desquamation"
            @input="onInput($event, 'desquamation', item)"
            class="small-input mr-md-4"
          />
        </div>
      </v-col>
      <v-col>
        <form-select
          type="number"
          :value="item.desquamation"
          :label="$t('scaling_severity')"
          :items="gravityOptions"
          :outlined="false"
          @input="onInput($event, 'desquamation', item)"
          clearable
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import FormText from "@/components/Form/Text";
import FormSelect from "@/components/Form/Select";
import { minValue, maxValue, required } from "vuelidate/lib/validators";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  components: {
    FormText,
    FormSelect,
  },
  validations: {
    item: {
      eczema_extent_percentage: {
        minValue: minValue(1),
        maxValue: maxValue(100),
        required,
      },
    },
  },
  data() {
    return {
      gravityOptions: [
        { text: "Mírná", value: 1 },
        { text: "Střední", value: 2 },
        { text: "Vážná", value: 3 },
        { text: "Velmi vážná", value: 4 },
      ],
    };
  },
  watch: {
    areaScore() {
      this.$emit("item", { ...this.item, eczema_extent: this.areaScore });
    },
    regionalScore() {
      this.$emit("item", { ...this.item, regional_score: this.regionalScore });
    },
  },
  computed: {
    areaScore() {
      if (
        this.item.eczema_extent_percentage >= 1 &&
        this.item.eczema_extent_percentage <= 9
      ) {
        return 1;
      } else if (
        this.item.eczema_extent_percentage >= 10 &&
        this.item.eczema_extent_percentage <= 29
      ) {
        return 2;
      } else if (
        this.item.eczema_extent_percentage >= 30 &&
        this.item.eczema_extent_percentage <= 49
      ) {
        return 3;
      } else if (
        this.item.eczema_extent_percentage >= 50 &&
        this.item.eczema_extent_percentage <= 69
      ) {
        return 4;
      } else if (
        this.item.eczema_extent_percentage >= 70 &&
        this.item.eczema_extent_percentage <= 89
      ) {
        return 5;
      } else if (
        this.item.eczema_extent_percentage >= 90 &&
        this.item.eczema_extent_percentage <= 100
      ) {
        return 6;
      } else {
        return 0;
      }
    },
    regionalScore() {
      const sumInfliction =
        parseInt(this.item.erythema) +
        parseInt(this.item.induration) +
        parseInt(this.item.desquamation);
      return parseFloat(
        (sumInfliction * this.areaScore * this.item.multiplicator).toFixed(2)
      );
    },
  },
  methods: {
    onInput(event, infliction, item) {
      let numberValue = parseInt(event);
      numberValue = numberValue || 0;
      const paiload = { ...item, [infliction]: numberValue };
      if (numberValue >= 0) {
        this.$emit("item", paiload);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
h3 {
  font-weight: 500;
  font-size: 1rem;
  color: gray;
}

#area-score {
  pointer-events: none;
}
</style>
