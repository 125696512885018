<template>
  <div class="card">
    <h1>{{ $t("patient_info_title") }}</h1>
    <p>
      {{ $t("patient_info_disclaimer") }}
    </p>

    <form-text
      :value="patient.initials"
      :label="$t('patient_identificator')"
      :validation="$v.patient.initials"
      validateOff
      @input="ontInput({ ...patient, initials: $event })"
    />
    <DatePicker
      :label="$t('birth_date')"
      :validation="$v.patient.birthdate"
      showYear
      @input="ontInput({ ...patient, birthdate: $event })"
    />

    <v-radio-group
      row
      :value="patient.gender"
      class="mt-0"
      @change="ontInput({ ...patient, gender: $event })"
    >
      <v-radio
        v-for="gender in genders"
        :key="gender.name"
        :label="gender.name"
        :value="gender.value"
      ></v-radio>
    </v-radio-group>
  </div>
</template>

<script>
import FormText from "@/components/Form/Text";
import DatePicker from "../Form/DatePicker.vue";
import { patientInfoValidation } from "../../use/validations";

export default {
  name: "UserCard",
  components: {
    FormText,
    DatePicker,
  },
  props: {
    patient: {
      type: Object,
      default: null,
    },
  },
  validations: {
    ...patientInfoValidation,
  },
  data() {
    return {
      genders: [
        { name: this.$t("man"), value: "male" },
        { name: this.$t("woman"), value: "female" },
      ],
    };
  },
  methods: {
    ontInput(patientData) {
      this.$emit("patient", patientData);
    },
    validateForm() {
      this.$v.$touch();
      return this.$v.$invalid;
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  height: 100%;
}
</style>
