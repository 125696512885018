<template>
  <div id="patient-list" class="card">
    <h2>{{ $t("patient_list") }}</h2>
    <v-data-table
      :headers="recordsListHeaders"
      :items="patients"
      :footer-props="footerProps"
      :item-class="rowClass"
      @click:row="clickOnRow($event)"
    >
      <template #footer.page-text="patients">
        {{ patients.pageStop }} z {{ patients.itemsLength }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "PatientList",

  props: {
    patients: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      activeRow: false,
      recordsListHeaders: [
        {
          text: this.$t("initials"),
          value: "initials",
          sortable: false,
        },
        {
          text: this.$t("gender"),
          value: "gender",
          sortable: false,
        },
        {
          text: this.$t("age"),
          value: "age",
          sortable: false,
        },
      ],
      footerProps: {
        "items-per-page-text": this.$t('number_items'),
      },
    };
  },

  methods: {
    rowClass(item) {
      if (item?.id === parseInt(this.$route.params.patientId)) {
        return "active";
      } else {
        return "";
      }
    },
    clickOnRow(event) {
      this.$emit("click-row", event);
    },
  },
};
</script>

<style lang="scss" scoped>
#patient-list {
  max-width: 20rem;
}

@media screen and (max-width: 960px) {
  #patient-list {
    max-width: 100%;
  }
}
</style>
