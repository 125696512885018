<template>
  <v-container>
    <router-link text :to="{ name: 'MyPatients' }">
      <v-icon left>mdi-chevron-left</v-icon>
      <span class="text-link">Zpět na seznam pacientů</span>
    </router-link>
    <div v-if="patientData" id="charts">
      <h1>Pacient {{ patientData.patient.initials }}</h1>

      <div class="card mb-4">
        <h2>{{ $t("pasi_progress") }}</h2>
        <BarChart
          :chart-data="createPasiDataSet('PASI')"
          :options="createOptions('PASI skóre')"
        />
      </div>

      <div
        v-for="bodyPartChart in bodyPartCharts"
        :key="bodyPartChart.name"
        class="card mb-4 chart"
      >
        <div
          class="chart__title d-flex justify-space-between"
          @click="bodyPartChart.show = !bodyPartChart.show"
        >
          <h2>{{ bodyPartChart.label }}</h2>
          <v-icon> mdi-chevron-down </v-icon>
        </div>
        <div v-show="bodyPartChart.show" class="chart__content">
          <div class="d-md-flex justify-space-between">
            <div class="d-flex justify-center">
              <HumanFront
                class="chart__human-image ml-0"
                :activeBodyPart="makeBodyPartActive(bodyPartChart.name)"
              />
              <HumanFront
                class="chart__human-image ml-0"
                :activeBodyPart="makeBodyPartActive(bodyPartChart.name)"
              />
            </div>
            <div class="chart__range">
              <BarChart
                :chart-data="createDataset(bodyPartChart.name, 'eczema_extent')"
                :options="createOptions('Rozsah')"
              />
            </div>
          </div>
          <div class="d-md-flex justify-space-between">
            <div
              v-for="bodyPartInfliction in bodyPartInflictions"
              :key="bodyPartInfliction.name"
            >
              <BarChart
                :chart-data="
                  createDataset(bodyPartChart.name, bodyPartInfliction.name)
                "
                :options="createOptions(bodyPartInfliction.label)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import BarChart from "@/components/Chart/BarChart";
import HumanFront from "../components/Chart/HumanFront.vue";
import ChartDataLabels from "chartjs-plugin-datalabels";

export default {
  components: {
    BarChart,
    HumanFront,
  },
  data() {
    return {
      patientData: null,
      plugins: [ChartDataLabels],
      bodyPartCharts: [
        {
          name: "head_neck",
          label: "Hlava a krk",
          show: true,
        },
        {
          name: "torso",
          label: "Trup",
          show: true,
        },
        {
          name: "upper_limbs",
          label: "Horní končetiny",
          show: true,
        },
        {
          name: "lower_limbs",
          label: "Dolní končetiny",
          show: true,
        },
      ],
      bodyPartInflictions: [
        {
          label: "Erytém",
          name: "erythema",
        },
        {
          label: "Indurance",
          name: "induration",
        },
        {
          label: "Šupení",
          name: "desquamation",
        },
      ],
    };
  },
  computed: {
    patientId() {
      return this.$route.params.patientId;
    },
  },
  async mounted() {
    try {
      ({ data: this.patientData } = await this.$axios.get(
        `patients/${this.patientId}`
      ));
    } catch (error) {
      console.log(error);
    }
  },

  methods: {
    makeBodyPartActive(bodyPartName) {
      const activeBodyPart = {
        head_neck: false,
        torso: false,
        upper_limbs: false,
        lower_limbs: false,
      };

      activeBodyPart[bodyPartName] = true;

      return activeBodyPart;
    },

    formatDate(date) {
      const unformatedDate = new Date(date);
      const month = unformatedDate.getMonth() + 1;
      const day = unformatedDate.getDate();
      const year = unformatedDate.getFullYear();
      const formatedDate = `${day}.${month}.${year}`;

      return formatedDate;
    },

    createPasiDataSet() {
      const pasiEvaluatedOn = [];
      const pasiDatasets = [];

      this.patientData.forms.map((form) => {
        pasiEvaluatedOn.push(this.formatDate(form.evaluated_on));
        pasiDatasets.push(form.pasi_score);
      });

      return {
        labels: pasiEvaluatedOn,
        datasets: [
          {
            label: "",
            backgroundColor: "#FFD6AD",
            data: pasiDatasets.reverse(),
            datalabels: {
              color: "gray",
              anchor: "end",
              align: "top",
              formatter: function (value, context) {
                let firstVisitScore = context.chart.data.datasets[0].data[0];
                return `PASI ${(
                  ((firstVisitScore - value) / firstVisitScore) *
                  100
                ).toFixed(0)}`;
              },
            },
          },
        ],
      };
    },

    createDataset(bodyPart, infliction) {
      const chartDataSets = [];
      const bodyPartEvaluatedOn = [];

      const chartData = {
        labels: [],
        datasets: [
          {
            label: "",
            backgroundColor: "",
            data: [],
            datalabels: {
              color: "gray",
              anchor: "end",
              align: "top",
            },
          },
        ],
      };

      chartData.datasets[0].data = chartDataSets.reverse();
      chartData.labels = bodyPartEvaluatedOn;

      //Assign chart color
      switch (bodyPart) {
        case "head_neck":
          chartData.datasets[0].backgroundColor = "#63cad8";
          break;
        case "torso":
          chartData.datasets[0].backgroundColor = "#79aea3";
          break;
        case "upper_limbs":
          chartData.datasets[0].backgroundColor = "#de6c83";
          break;
        case "lower_limbs":
          chartData.datasets[0].backgroundColor = "#c1aac0";
          break;
        default:
          chartData.datasets[0].backgroundColor = "#204131";
          break;
      }

      this.patientData.forms.map((form) => {
        const bodyParts = form.body_parts;
        const filteredBodyPart = bodyParts.find(
          ({ type }) => type === bodyPart
        );

        chartDataSets.push(filteredBodyPart[infliction]);

        bodyPartEvaluatedOn.push(this.formatDate(form.evaluated_on));
      });

      return chartData;
    },

    createOptions(title) {
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
              scaleLabel: {
                display: true,
                labelString: "Závažnost",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Datum",
              },
            },
          ],
        },
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: title,
          padding: 35,
        },
        responsive: true,
        maintainAspectRatio: false,
      };

      return options;
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
  .v-icon {
    color: var(--v-primary-base);
  }
  display: block;
  margin-bottom: 1rem;

  &:hover {
    .text-link {
      text-decoration: underline;
    }
  }
}

#charts {
  margin-top: 2rem;
}

.chart {
  &__content {
    margin-top: 2rem;
  }

  &__title {
    cursor: pointer;
    .v-icon {
      font-size: 2rem;
    }

    &:hover {
      h2 {
        color: var(--v-accent-base);
      }
    }
  }

  &__human-image {
    min-width: 15rem;
  }

  &__range {
    width: 60%;

    div {
      height: 100%;
    }
  }
}

@media screen and (max-width: 960px) {
  .chart {
    &__human-image {
      min-width: 0;
      max-width: 15rem;
    }
    &__range {
      width: 100%;
    }
  }
}
</style>
