<template>
  <div id="registration">
    <RegistrationCard />
  </div>
</template>

<script>
import RegistrationCard from "@/components/Registration/RegistrationCard.vue";
export default {
  components: { RegistrationCard },
};
</script>

<style>
</style>