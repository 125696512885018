<template>
  <div class="card">
    <h1>{{$t('login_title')}}</h1>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from "@/components/Form/Login/LoginForm";

export default {
  name: "LoginCard",
  components: {
    LoginForm,
  },
};
</script>

<style lang="scss" scoped>
.card {
  max-width: 25rem;
  margin: auto;
}
</style>
