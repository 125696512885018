<template>
  <div v-if="patient" id="patient-records" class="card">
    <div class="d-flex justify-space-between">
      <h2 class="mb-4">
        {{ $t("patient") }} {{ patient.initials }} {{ patient.age }}
      </h2>
      <v-menu offset-y left>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon> <v-icon>mdi-dots-vertical</v-icon></v-btn>
        </template>
        <v-list dense>
          <v-list-item link v-for="menuItem in menuItems" :key="menuItem.name">
            <v-list-item-title @click="menuItem.action"
              ><v-icon class="mr-2">{{ menuItem.icon }}</v-icon>
              {{ menuItem.name }}</v-list-item-title
            >
          </v-list-item>
        </v-list>
      </v-menu>
    </div>
    <v-btn
      depressed
      color="primary"
      class="my-4 base-btn"
      @click="
        $router.push({ name: 'Charts', params: { patientId: patientId } })
      "
      >{{ $t("show_charts") }}</v-btn
    >

    <div class="d-md-flex patient-recores-table-wrapper">
      <div id="patient-records-list">
        <v-btn depressed color="primary" @click="redirectToAddEvaluationt">{{
          $t("add_evaluation")
        }}</v-btn>
        <v-data-table
          v-if="patientForms.length"
          :headers="patientRecordsListHeaders"
          :items="patientForms"
          :footer-props="footerProps"
          :item-class="rowClass"
          @click:row="onRowClick"
        >
          <template v-slot:item.evaluated_on="{ item }">
            {{ formatedDate(item.evaluated_on) }}
          </template>
          <template v-slot:item.pasi_score="{ item }">
            {{ item.pasi_score }}
            <v-tooltip v-if="item.pasi_score > 10" top>
              <template v-slot:activator="{ on }">
                <v-icon class="alert-icon" v-on="on">
                  mdi-alert-circle-outline
                </v-icon>
              </template>
              <span>
                U pacientů, kteří mají PASI větší než 10 je možné zvážit
                nasazení systémové léčby</span
              >
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="patientForms.length > 1"
              small
              class="mr-2"
              @click="$emit('on-delete-patient-form', item)"
            >
              mdi-close
            </v-icon>
          </template>
          <template #footer.page-text="patients">
            {{ patients.pageStop }} z {{ patients.itemsLength }}
          </template>
        </v-data-table>
      </div>
      <div id="patient-record" class="flex-grow-1">
        <h2 id="ww" class="d-md-none">Detail návštěvy</h2>
        <v-data-table
          v-if="patientForms.length"
          :headers="patientRecordHeaders"
          :items="translateBodyParts"
          hide-default-footer
        />
      </div>
    </div>
  </div>
</template>

<script>
import formatDate from "@/use/formatDate.js";
import useTrasnlatedBodyParts from "@/use/translateBodyParts.js";

export default {
  name: "PatientRecords",
  props: {
    patientForms: {
      type: Array,
      required: true,
    },
    patient: {
      tyep: Object,
      required: true,
    },
  },

  computed: {
    translateBodyParts() {
      return useTrasnlatedBodyParts(this.bodyParts);
    },

    patientId() {
      return this.$route.params.patientId;
    },
  },

  data() {
    return {
      bodyParts: [],
      footerProps: {
        "items-per-page-text": this.$t("number_items"),
      },
      patientRecordsListHeaders: [
        {
          text: this.$t("date_of_recording"),
          value: "evaluated_on",
          sortable: false,
        },
        {
          text: this.$t("pasi_score"),
          value: "pasi_score",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
        },
      ],
      patientRecordHeaders: [
        {
          text: this.$t("type"),
          value: "type",
          sortable: false,
        },
        {
          text: this.$t("range"),
          value: "eczema_extent",
          sortable: false,
        },
        {
          text: this.$t("erytem"),
          value: "erythema",
          sortable: false,
        },
        {
          text: this.$t("induration"),
          value: "induration",
          sortable: false,
        },
        {
          text: this.$t("scaling"),
          value: "desquamation",
          sortable: false,
        },
      ],
      menuItems: [
        {
          name: this.$t("removing_patient"),
          icon: "mdi-delete",
          action: this.onDeletePatient,
        },
        {
          name: this.$t("send_report"),
          icon: "mdi-email",
          action: this.onSendReport,
        },
      ],
    };
  },

  mounted() {
    if (this.$route.params.formId) {
      const filteredPatientForm = this.patientForms.find(
        ({ id }) => id === parseInt(this.$route.params.formId)
      );
      this.bodyParts = filteredPatientForm.body_parts;
    }
  },

  watch: {
    "$route.params.formId": function (formId) {
      const filteredPatientForm = this.patientForms.find(
        ({ id }) => id === formId
      );
      this.bodyParts = filteredPatientForm.body_parts;
    },
  },

  methods: {
    redirectToAddEvaluationt() {
      this.$router.push({
        name: "NewEvaluation",
        params: { patientId: this.patientId },
      });
    },

    formatedDate(item) {
      return formatDate(item);
    },

    onSendReport() {
      this.$emit("on-send-report");
    },

    onDeletePatient() {
      this.$emit("on-delete-patient");
    },

    onEditPatient() {
      this.$emit("on-edit-patient");
    },

    rowClass(item) {
      if (item?.id === parseInt(this.$route.params.formId)) {
        return "active";
      } else {
        return "";
      }
    },

    onRowClick(form) {
      if (form.id !== parseInt(this.$route.params.formId)) {
        this.$router.replace({ params: { formId: form.id } });
        this.bodyParts = form.body_parts;
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        this.$vuetify.goTo("#patient-records", {
          container: "#patient-records",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-recores-table-wrapper {
  border-top: 1px solid gainsboro;
}
#patient-records-list {
  max-width: 20rem;
  border-right: 1px solid gainsboro;
  padding: 2rem 1rem 0 0;
  .v-btn {
    width: 100%;
    margin-bottom: 1rem;
  }
}

#patient-record {
  padding: 2rem 1rem 1rem 1rem;

  .v-data-table {
    width: 100%;
  }
}

.alert-icon {
  color: var(--v-primary-base);
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.v-menu__content {
  margin-top: 0.5rem;
}

@media screen and (max-width: 960px) {
  #patient-records {
    margin: -2rem;
  }

  #patient-records-list {
    max-width: 100%;
    border-right: none;
    padding: 0;
    .v-btn {
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  #patient-record {
    padding: 0;
    margin-top: 4rem;
  }
}
</style>
