<template>
  <div id="login-form">
    <NotificationAlert :notifications="errors" class="mb-8" />
    <form @submit.prevent="onSubmit">
      <FormText
        v-model="credentials.email"
        :label="$t('email')"
        :validation="$v.credentials.email"
      />
      <FormText
        v-model="credentials.password"
        :label="$t('password')"
        type="password"
        class="mt-2"
        :validation="$v.credentials.password"
      />
      <v-checkbox
        class="mt-0"
        :label="$t('stay_login')"
        v-model="staySignedIn"
      ></v-checkbox>
      <v-btn
        v-show="!isloginModal"
        depressed
        color="primary"
        type="submit"
        :loading="loading"
        >{{$t('login_text')}}</v-btn
      >
      <div v-if="!isloginModal" class="d-flex justify-space-between mt-8">
        <router-link :to="{ name: 'Registration' }"
          >{{$t('new_registration')}}</router-link
        >
        <router-link :to="{ name: 'ForgottenPassword' }"
          >{{$t('forgot_password')}}</router-link
        >
      </div>
    </form>
  </div>
</template>

<script>
import { logInValidation } from "@/use/validations.js";
import useNotification from "@/use/notifications";

import FormText from "@/components/Form/Text.vue";
import NotificationAlert from "@/components/NotificationAlert.vue";

export default {
  name: "LoginForm",
  components: { FormText, NotificationAlert },
  props: {
    isloginModal: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      credentials: {
        email: "",
        password: "",
      },
      staySignedIn: false,
      errors: [],
      loading: false,
    };
  },
  validations: {
    ...logInValidation,
  },
  methods: {
    async onSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          this.loading = true;
          const paiload = {
            credentials: { ...this.credentials },
            stay_signed_in: this.staySignedIn,
          };
          const { data } = await this.$axios.post("/doctors/signin", paiload);
          useNotification("Přihlášení proběhlo úspěšně");
          this.$store.dispatch("setToken", data.auth);
          this.$store.dispatch("setUser", data.user);
          const token = data.auth.access_token;
          this.$axios.defaults.headers.common[
            "Authorization"
          ] = `BEARER ${token}`;

          if (!this.isloginModal) {
            this.$router.push({ name: "Home" });
          } else {
            this.$store.commit("setLoginModal", false);
          }
          this.errors = [];
          location.reload();
        } catch (error) {
          const serverErrors = error.response.data.errors.map((error) => {
            error.type = "error";
            return { ...error };
          });
          this.errors = [...serverErrors];
          if (this.isloginModal) {
            this.$emit("hasErrors");
          }
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-btn {
  width: 100%;
}
</style>
