<template>
  <dialog-window
    v-model="$store.state.showLoginModal"
    :title="$t('login_again_title')"
    :text="$t('login_again_text')"
    :closeButtonText="$t('log_out')"
    :confirmButtonText="$t('login_text')"
    width="500"
    @actionButton="logIn"
    @closeDialog="logOut"
  >
    <LoginForm ref="loginForm" isloginModal />
  </dialog-window>
</template>

<script>
import DialogWindow from "@/components/DialogWindow.vue";
import LoginForm from "@/components/Form/Login/LoginForm";

export default {
  name: "LoginModal",
  components: {
    DialogWindow,
    LoginForm,
  },
  methods: {
    async logIn() {
      await this.$refs.loginForm.onSubmit();
    },

    async logOut() {
      this.$store.commit("setDefaultState");
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style></style>
