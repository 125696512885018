import store from "../store/index";

export default async () => {
  const localStorageItem = JSON.parse(localStorage.getItem("vuex"));

  const refreshToken = localStorageItem?.token?.refresh_token;

  const refresTokenExpirationDate = new Date(
    localStorageItem?.token?.refresh_token_expires_at
  );

  if (new Date() > refresTokenExpirationDate) {
    store.commit("setLoginModal", true);
  }
  if (!refreshToken) {
    return;
  }
  const tokenExpirationDate = new Date(
    localStorageItem?.token.access_token_expires_at
  );
  const tokenExpirationDateMinusMinute = new Date(
    tokenExpirationDate.getTime() - 60000
  );

  if (new Date() < tokenExpirationDateMinusMinute) {
    return;
  }

  await store.dispatch("fetchFreshToken", refreshToken);
  console.log("new token is issued");

  const localStorageItemUpdated = JSON.parse(localStorage.getItem("vuex"));
  const token = localStorageItemUpdated?.token?.access_token;

  return token;
};
